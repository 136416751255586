export const config = {
  help: {
    url: 'https://help.medely.com',
    setYourselfUpForSuccess:
      'https://help.medely.com/en/articles/6971077-professional-how-to-set-yourself-up-for-success-at-medely',
    clockInClockOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    cancellationsAndNoShow: 'https://help.medely.com/en/articles/1871897-cancellation-policy',
    limitedTimeIncentiveProgram:
      'https://help.medely.com/en/articles/9736000-limited-time-incentive-program',
  },
};
