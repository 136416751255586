import { IAssignment, IJob, IMarket, JobStatusEnum as JobStatus } from '@medely/types';
import { IBadgedJob, IBadgedAssignment, Badge } from './positionsBadges';
import get from 'lodash/get';
import { TrackedEvents } from '../constants/analytics';
import moment from 'moment';
import { gql } from 'graphql-request';
import { jobBonusCents } from './jobUtils';

const AVAILABLE_ASSIGNMENT_STATUSES: string[] = ['available', 'matched'];
const BOOKED_ASSIGNMENT_STATUSES: string[] = ['booked', 'in_progress', 'completed'];
const AVAILABLE_JOB_STATUSES: string[] = [JobStatus.Active];
const BOOKED_JOB_STATUSES: string[] = [
  JobStatus.Booked,
  JobStatus.CanceledWithFee,
  JobStatus.Completed,
  JobStatus.ClockedIn,
  JobStatus.ClockedOut,
  JobStatus.Disputed,
  JobStatus.HeldForDisputeReview,
  JobStatus.LateCancellation,
  JobStatus.NoShow,
];

const isTrackableAssignmentEvent = (status: string): boolean => {
  return (
    AVAILABLE_ASSIGNMENT_STATUSES.includes(status) || BOOKED_ASSIGNMENT_STATUSES.includes(status)
  );
};

const isTrackableJobEvent = (status: string): boolean => {
  return AVAILABLE_JOB_STATUSES.includes(status) || BOOKED_JOB_STATUSES.includes(status);
};

const eventTrackingURL = ({ itemId, event }: { itemId?: number; event: TrackedEvents }): string => {
  switch (event) {
    case TrackedEvents.ASSIGNMENT_VIEW:
      return `v3/professional/assignments/${itemId}/tracking/viewed`;
    case TrackedEvents.JOB_VIEW:
      return `v3/professional/jobs/${itemId}/tracking/viewed`;
    case TrackedEvents.ASSIGNMENT_LIST_VIEW:
      return 'v3/professional/assignments/tracking/viewed';
    case TrackedEvents.JOB_LIST_VIEW:
      return 'v3/professional/jobs/tracking/viewed';
    case TrackedEvents.QUALIFICATION_EDIT_STARTED:
    case TrackedEvents.QUALIFICATION_EDIT_FINISHED:
      return 'v3/professional/tracked_events';
  }
};

export interface IViewedAssignmentAnalyticsMetadata {
  assignment_id: IAssignment['id'];
  city: IAssignment['primary_location']['address_city'];
  market: IAssignment['primary_location']['market']['display_name'];
  state: IAssignment['primary_location']['state']['abbreviation'];
  time_viewed: number;
  is_eligible: IAssignment['can_apply'];
  pay_per_week_cents: IAssignment['pay_per_week_cents'];
  one_time_bonus_burden_cents: IAssignment['one_time_bonus_burden_cents'];
  status: IAssignment['status'];
}

const viewedAssignmentAnalyticsMetadata = ({
  assignment,
}: {
  assignment: IAssignment;
}): IViewedAssignmentAnalyticsMetadata => {
  return {
    assignment_id: assignment.id,
    city: assignment.primary_location.address_city,
    market: assignment.primary_location.market?.display_name,
    state: assignment.primary_location.state.abbreviation,
    time_viewed: moment().hour(),
    is_eligible: assignment.can_apply,
    pay_per_week_cents: assignment.pay_per_week_cents,
    one_time_bonus_burden_cents: assignment.one_time_bonus_burden_cents,
    status: assignment.status,
  };
};

export interface IViewedJobAnalyticsMetadata {
  job_id: IJob['id'];
  market?: IMarket['display_name'];
  time_viewed: number;
  is_eligible: IJob['can_book_job'];
  payout_total_amount_cents: IJob['job_billing_sums']['payout_total_amount_cents'];
  total_payout_bonus_cents: number;
  status: IJob['status'];
}

const viewedJobAnalyticsMetadata = ({ job }: { job: IJob }): IViewedJobAnalyticsMetadata => {
  return {
    job_id: job.id,
    market: job.location.market?.display_name,
    time_viewed: moment().hour(),
    is_eligible: job?.can_book_job,
    payout_total_amount_cents: job?.job_billing_sums?.payout_total_amount_cents,
    total_payout_bonus_cents: jobBonusCents(job),
    status: job?.status,
  };
};

export type Listing = {
  id: number;
  position: number;
  is_eligible: boolean;
  payout_total_amount_cents?: number;
  total_payout_bonus_cents?: number;
  pay_per_week_cents?: number;
  one_time_bonus_burden_cents?: number;
  badges: Badge[];
} & Partial<IBadgedJob & IBadgedAssignment>;

const isPerDiem = (item: Partial<IJob & IAssignment>): boolean => 'can_book_job' in item;

const isEligible = (item: Partial<IJob & IAssignment>): boolean => {
  const isEligibleProp = isPerDiem(item) ? 'can_book_job' : 'can_apply';

  return !!get(item, isEligibleProp);
};
export const TRACKING_ITEM_INFO_ASSIGNMENT_FRAGMENT = gql`
  fragment trackingItemInfoAssignmentFragment on Assignment {
    id
    can_apply(professional_id: $professional_id)
    pay_per_week_cents
    one_time_bonus_burden_cents
    primary_location {
      address_city
      market {
        display_name
      }
      state {
        abbreviation
      }
    }
    positions {
      display_name
    }
  }
`;

const generateItemInfo = (item: Listing, position: number): Listing => {
  const itemInfo = isPerDiem(item)
    ? {
        is_eligible: !!item.can_book_job,
        payout_total_amount_cents: item.job_billing_sums?.payout_total_amount_cents,
        total_payout_bonus_cents: jobBonusCents(item),
      }
    : {
        is_eligible: !!item.can_apply,
        pay_per_week_cents: item.pay_per_week_cents,
        one_time_bonus_burden_cents: item.one_time_bonus_burden_cents,
      };

  return {
    id: item.id,
    position,
    badges: item.badges ?? [],
    ...itemInfo,
  };
};

const generateItemsInfo = (
  items: Partial<IBadgedJob>[] | Partial<IBadgedAssignment>[],
): {
  listings: Listing[];
  eligible_count: number;
} => {
  return (items as Listing[]).reduce(
    (
      accumulator: {
        listings: Listing[];
        eligible_count: number;
      },
      item: Listing,
      index: number,
    ) => {
      const position = index + 1;
      const listing = generateItemInfo(item, position);

      accumulator.listings.push(listing);
      accumulator.eligible_count += Number(isEligible(item));

      return accumulator;
    },
    { listings: [], eligible_count: 0 },
  );
};

export {
  generateItemInfo,
  generateItemsInfo,
  isTrackableAssignmentEvent,
  isTrackableJobEvent,
  eventTrackingURL,
  viewedAssignmentAnalyticsMetadata,
  viewedJobAnalyticsMetadata,
};
