import { TaskCard, TaskCardProps } from '@medely/ui-kit';
import React from 'react';
import { config } from '@src/config';
import { useProNavigate } from '@src/contexts';
import { TaskCardLayout } from '../TaskCard';

export type DxGyCampaignContainerProps = {
  title: TaskCardProps['title'];
  body: TaskCardProps['body'];
};

export const DxGyCampaignContainer = ({ title, body }: DxGyCampaignContainerProps) => {
  const { openUrl } = useProNavigate();
  const onClick = () => openUrl(config.help.limitedTimeIncentiveProgram);

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'circle-dollar-to-slot', color: 'primary' }}
        title={title}
        body={body}
        onClick={onClick}
      />
    </TaskCardLayout>
  );
};
