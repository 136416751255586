import { useGraphQLContext } from '@medely/ui-kit';
import { useNetworkRequest } from './useNetworkRequest';

const useGraphQLRequest = () => {
  const { transform } = useGraphQLContext();
  const { gqlRequest } = useNetworkRequest();

  const request = async (query: string, variables?: unknown) => {
    return gqlRequest(transform(query), variables);
  };

  return { gqlRequest: request, request };
};

export default useGraphQLRequest;
