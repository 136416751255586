import { type Routes } from '@medely/ui-kit';

// Key = Route name
// Value = Params type
// Optional params should use | undefined, not ?
// Currently only primitive values are supported.
// All params are query params (no path params)
export type ProRoutes = {
  AdjustmentDetail: {
    id: number;
  };
  AvailableAssignmentsLocal: never;
  EarningsPayoutDetails: {
    id: number;
  };
  EarningsPending: never;
  JobDetails: {
    id: number;
  };
  Profile: never;
};

export const proRoutes: Routes<ProRoutes> = {
  AdjustmentDetail: {
    url: '/earnings/adjustments/:id',
  },
  AvailableAssignmentsLocal: {
    url: '/available/assignments/local',
  },
  EarningsPayoutDetails: {
    url: '/earnings/payouts/:id',
  },
  EarningsPending: {
    url: '/earnings/pending',
  },
  JobDetails: {
    url: '/jobs/:id',
  },
  Profile: {
    url: '/profile',
  },
};
