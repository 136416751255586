import { GET_EXPENSE_ADJUSTMENT } from '../graphql/expenseAdjustmentQueries';
import { IExpenseAdjustment, IPayout } from '@medely/types';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import useGraphQLRequest from './useGraphQLRequest';

type IUseExpenseAdjustmentReturn = {
  expenseAdjustment: IExpenseAdjustment & { payout: IPayout };
  isLoading: boolean;
};

type UseExpenseAdjustmentProps = {
  id: number;
};

export const useExpenseAdjustment = ({
  id,
}: UseExpenseAdjustmentProps): IUseExpenseAdjustmentReturn => {
  const { request } = useGraphQLRequest();

  const fetchExpenseAdjustment = async ({
    queryKey: [_entity, id],
  }: QueryFunctionContext<[unknown, number]>) => {
    const { expenseAdjustment } = await request(GET_EXPENSE_ADJUSTMENT, { id });
    return expenseAdjustment;
  };

  const { data, isLoading } = useQuery(['expenseAdjustment', id], fetchExpenseAdjustment);

  return {
    expenseAdjustment: data ?? {},
    isLoading,
  };
};

export default useExpenseAdjustment;
