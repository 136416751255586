import { gql } from 'graphql-request';

export const GET_TODOS = gql`
  query ProfessionalTodos {
    professionalTodos {
      available_for_extensions
      active_jobs_requesting_to_work_facility_ids
      active_jobs_requesting_to_work_ids
      notifications_turned_on
      sms_turned_on
      has_position_with_completed_onboarding
      expired_or_missing_credentials_count
      incomplete_skill_assessments_count
      assignments_missing_conditions
      offers_count
      assignment_offers
      extension_offers
      unsigned_timesheet_ids
      profile_completeness
      needs_payment_info
      days_until_debit_card_expires
      has_invalid_credentials
      next_shift
      booked_jobs_count
      finished_jobs_count
      unsigned_addendum_assignment_ids
      missing_direct_deposit
      missing_ssn_for_assignment
      missing_premier_credentials
      missing_w2_per_diem_onboarding
      disputes_waiting_on_professional
      campaigns_status
    }
  }
`;
