import { getPayoutsQuery } from '../graphql/payoutsQueries';
import { IPayout } from '@medely/types';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useCurrentUser } from './useCurrentUser';
import useGraphQLRequest from './useGraphQLRequest';

type IUsePayoutsReturn = {
  payouts: IPayout[];
  isLoading: boolean;
};

type UsePayoutsProps = {
  monthStart: string;
  monthEnd: string;
  onError: (e: Error) => void;
};

export const usePayouts = ({
  monthStart,
  monthEnd,
  onError,
}: UsePayoutsProps): IUsePayoutsReturn => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();
  const payoutsQuery = getPayoutsQuery(currentUser);

  const fetchPayouts = async ({
    queryKey: [_entity, monthStart, monthEnd],
  }: QueryFunctionContext<[unknown, string, string]>) => {
    const { payouts } = await request(payoutsQuery, {
      input: {
        search: {
          w2_submit_by_time_from: monthStart,
          w2_submit_by_time_to: monthEnd,
          hide_zero_value_payouts: true,
        },
        orderBy: {
          w2_submit_by_time: true,
        },
      },
    });
    return payouts;
  };

  const { data, isLoading } = useQuery(['payouts', monthStart, monthEnd], fetchPayouts, {
    onError,
  });

  return {
    payouts: data ?? [],
    isLoading,
  };
};

export default usePayouts;
